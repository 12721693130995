html {
	position: relative;
  	min-height: 100%;
}

body {
  	margin-bottom: 60px;
}

.page-wrapper {
	margin-top: 60px;
}

.dev-mode-indicator-label {
	position:fixed;
	left:15px;
	bottom:75px;
	z-index:100;
}

.dev-mode-info-label {
	position:fixed;
	left:15px;
	bottom:45px;
	z-index:100;
}

.dev-mode-mock-rating-info-label {
	position:fixed;
	left:15px;
	bottom:15px;
	z-index:100;
}

.dinkly-admin-jumbotron {
	background-color:transparent;
}

.dataTables_wrapper .row {
	margin-right:0;
	margin-left:0;
}

#add-group-modal .modal-dialog {
	width:375px;
}

#manage-permissions-modal .modal-dialog {
	width: 800px;
}

#footer {
	background-color: #1477af;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	height: 60px;
}

#footer p {
	color: #FFF;
	margin: 20px 0;
}

.admin-login {
	height: 400px;
	margin-top: 10%;
}

.admin-login .panel-info {
	width: 80%;
}

.admin-container {
	margin-bottom: 0;
}